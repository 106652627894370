import React from 'react';

function AddTokenButton({ tokenAddress, symbol, img }) {
    const handleAddToken = async () => {
        if (window.ethereum) {
            try {
                // Request MetaMask to add a custom token
                await window.ethereum.request({
                    method: 'wallet_watchAsset',
                    params: {
                        type: 'ERC20',
                        options: {
                            address: tokenAddress, // The token contract address
                            symbol: symbol,       // The token symbol (e.g., DAI)
                            decimals: 18,     // The token decimals
                            image: img,     // URL of the token's logo
                        },
                    },
                });
            } catch (error) {
                console.error('Error adding token:', error);
            }
        } else {
            console.error('MetaMask not found. Please install MetaMask and connect to your wallet.');
        }
    };

    return (
        <img onClick={handleAddToken} src="/assests/metamaskMini.svg" alt="metamaskMini" className="cP" />
    );
}

export default AddTokenButton;
