import React, { useState, useEffect } from "react";
import "./earn.scss";
import axios from "axios";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Slider } from "@material-ui/core";
import EarnFee from "../../hooks/dataFetchers/earnFee";
import DailyPayout from "../../hooks/dataFetchers/dailyPayout";
import { useWeb3React } from "@web3-react/core";
import { EarnApy } from "../../hooks/dataFetchers/earnApy";
import { TotalReturns } from "../../hooks/dataFetchers/totalReturns";
import { CheckBalance } from "../../hooks/dataFetchers/balanceOfHydt";
import { EarnApprove } from "../../hooks/dataSenders/earnApprove";
import { HydtAllowance } from "../../hooks/dataFetchers/hydtAllowance";
import { EarnStake } from "../../hooks/dataSenders/earnStake";
import Environment from "../../utils/environment";
import Loader from "../../hooks/loader";
import moment from "moment";
import useWeb3 from "hooks/useWeb3";
import EarnChild from "./EarnChild";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import VolumeUp from "@mui/icons-material/VolumeUp";
import MuiInput from "@mui/material/Input";
import { styled } from "@mui/material/styles";
import { controlResolverAbi } from "utils/contractHelpers";
import { CListGroup } from "@coreui/react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EarnHygtApy } from "../../hooks/dataFetchers/hygtApyEarn";

const Earn = () => {
  const [addCls, setcls] = useState(false);
  // console.log(addCls, "addCls");
  const { depositAmount } = EarnFee();
  const { payoutDaily } = DailyPayout();
  const { apyAmount } = EarnApy();
  const { returnHydt } = TotalReturns();
  const { balanceOfHydt } = CheckBalance();
  const { AllowanceHydt } = HydtAllowance();
  const { approveEarn } = EarnApprove();
  const { stakeEarn } = EarnStake();
  const { hygtapyAmount } = EarnHygtApy();
  const web3 = useWeb3();
  const { account } = useWeb3React();
  const [update, setupdate] = useState('0');
  const [depositFee, setDepositFee] = useState("");
  const [payoutPer, setPayoutPer] = useState("");
  const [apy, setApy] = useState("");
  const [balanceOf, setBalanceOf] = useState("");
  const [totalReturns, setTotalReturns] = useState("");
  const [approv, setApprov] = useState("");
  const [allowance, setAllowance] = useState("");
  const [hydtAmount, setHydtAmount] = useState("");
  const [hydtBal, setHydtBal] = useState("");
  const [mainLoader, setMainLoader] = useState(false);
  const [activeDeposit, setActiveDeposit] = useState("");
  const [rend, setRend] = useState(false);
  const [hygtApyArr, setHygtApyArr] = useState([0])
  // console.log('hydtAmount', hydtAmount)
  const DepositFee = async () => {
    let result = await depositAmount();
    setDepositFee(result);
  };
  const Payouts = async () => {
    let result = await payoutDaily(update);
    setPayoutPer(result);
  };
  const ApyFun = async () => {
    let result = await apyAmount(update);
    // console.log('depositAmount', result)
    setApy(result);
  };
  // console.log('depositAmount update', update)
  // const ReturnsBal = async (update) => {
  //   let result = await returnHydt(update);
  //   setTotalReturns(result);
  // };

  const getBalance = async () => {
    let result = await balanceOfHydt(account);
    setBalanceOf(result);
    setMainLoader(false);
  };
  const ApproveFun = async () => {
    if (!account) {
      toast.info("Connect Your Wallet", {
        position: "top-right",
        autoClose: 2000,
      })
      return
    }
    if (parseFloat(balanceOf) === 0) {
      toast.info("You don't have enough balance", {
        position: "top-right",
        autoClose: 2000,
      })
      return
    }
    if (parseFloat(hydtAmount) === 0) {
      toast.info("Enter HYDT Amount", {
        position: "top-right",
        autoClose: 2000,
      })
      return
    }
    try {
      setMainLoader(true);
      let result = await approveEarn();
      setApprov(result);
      HydtAllow();
      toast.success("Approved Successfully", {
        position: "top-right",
        autoClose: 2000,
      });
    } catch (error) {
      let a = error.message.slice(29, 47);
      toast.error(a?.includes('transaction') ? 'User Denied Transaction' : error, {
        position: "top-right",
        autoClose: 2000,
      });
      setMainLoader(false);
    }
  };
  // console.log("updateupdate", update, hydtAmount);
  const StakeFun = async (update, hydtAmount) => {
    try {
      if (hydtAmount <= balanceOf && hydtAmount > 0) {
        setMainLoader(true);
        let res = 0;
        // if (hydtAmount.length > 19 ){
        //   res = hydtAmount;
        // }else{
        //   res =hydtAmount
        // }
        // console.log("hydtAmounthydtAmount", hydtAmount, "lenght", hydtAmount.length,);
        // console.log("upppppppppp", parseFloat(hydtAmount).toFixed(18));
        let result = await stakeEarn(
          hydtAmount,
          update
        );
        if (result) {
          setTimeout(() => {
            getBalance();
            getDepositData();
          }, 3000);
        }
        setHydtAmount("");
        let result1 = await AllowanceHydt();
        if (result1) {
          setAllowance(result1);
        }
        setMainLoader(false);
        toast.success("Staked Successfully", {
          position: "top-right",
          autoClose: 2000,
        });
      }
      else {
        toast.error("Enter correct HYDT Amount", {
          position: "top-right",
          autoClose: 2000,
        });
      }
    } catch {
      setMainLoader(false);
    }
    // setApprov(result);
  };
  const [status, setStatus] = useState(false);
  const HydtAllow = async () => {
    try {
      let result = await AllowanceHydt();
      // console.log("AllowanceHydtAllowanceHydt23423432423432", result);
      setAllowance(result);
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };
  const HydtAllow111 = async () => {
    try {
      let balanceOfUpdated = await balanceOfHydt(account);
      let result = await AllowanceHydt();
      if (balanceOfUpdated) {
        if (result < balanceOfUpdated) {
          // console.log("AllowanceHydtAllowanceHydt", result, "sxsc", balanceOfUpdated);
          setAllowance(0);
        }
        else {
          setAllowance(result);
        }
      }
      setMainLoader(false);
    } catch {
      setMainLoader(false);
    }
  };

  // console.log("statusstatus", status);
  useEffect(() => {
    if (account) {
      DepositFee();
      getBalance();
      HydtAllow();
    }
  }, [account]);
  useEffect(() => {
    // console.log('first account', account);
    ApyFun(update);
    Payouts();
  }, [update]);

  const handleHydt = (e) => {
    // console.log("33333333", e.target.value);
    setHydtAmount(e.target.value);
    if (balanceOf && e.target.value <= balanceOf && e.target.value > 0) {
      setValue((e.target.value / balanceOf) * 100);
    }

    if (e.target.value > 0) {
      let dailyPayouts = [1.156, 0.611, 0.356];
      let lockPeriods = [90, 180, 365];
      let y = (((0.985 * e.target.value) * dailyPayouts[update]) / 100) * lockPeriods[update];
      // console.log("totalReturnstotalReturns", y)
      setTotalReturns(y);
    }
  };
  // console.log("approv", totalReturns);
  //Graph Apis
  const getDepositData = () => {
    setMainLoader(true);
    let acc = JSON.stringify(account);
    var data = JSON.stringify({
      query: `query 
      MyQuery {stakes(
    where: {user: ${acc}, status: true}
    orderBy: startTime
    orderDirection: desc
  ) {
    user
    index
    status
    startTime
    lockPeriod
    amount
    APY
    endTime
  }}`,
    });
    var config = {
      method: "post",
      url: Environment.hydtGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        setActiveDeposit(response?.data?.data?.stakes);
        // console.log("minted", response);
        setMainLoader(false);
      })
      .catch(function (error) {
        setMainLoader(false);
      });
  };
  useEffect(() => {
    if (account) {
      getDepositData();
    }
  }, [account]);

  //range slider
  const [value, setValue] = React.useState(0);
  const handleSliderChange = (event, newValue) => {

    // console.log("dsscdcdsc3333", newValue);
    setValue(newValue);
    setHydtAmount((newValue * balanceOf) / 100);

    let dailyPayouts = [1.156, 0.611, 0.356];
    let lockPeriods = [90, 180, 365];
    let y = (((((0.985 * newValue) * balanceOf) / 100) * dailyPayouts[update]) / 100) * lockPeriods[update];
    // console.log("totalReturnstotalReturns", y)
    setTotalReturns(y);
  };

  const handleInputChange = (event) => {
    // console.log("11111");
    // console.log("balance1111111111", (event.target.value * balanceOf) / 100);
    setHydtAmount((event.target.value * balanceOf) / 100);
    setValue(event.target.value === "" ? "" : Number(event.target.value));
  };

  const handleBlur = () => {
    // console.log("22222");
    // console.log("balanceOfbalanceOf", value);
    if (value < 0) {
      setValue(0);
    } else if (value > 100) {
      setValue(100);
    }
  };
  const Input = styled(MuiInput)`
    width: 42px;
  `;
  const handleMaxBal = () => {
    setHydtAmount(balanceOf);
    if (balanceOf > 0) {
      setValue(balanceOf && (balanceOf / balanceOf) * 100);
    }
    let dailyPayouts = [1.156, 0.611, 0.356];
    let lockPeriods = [90, 180, 365];
    let y = (((0.985 * balanceOf) * dailyPayouts[update]) / 100) * lockPeriods[update];
    // console.log("totalReturnstotalReturns", y)
    setTotalReturns(y);
    // setRend(!rend);
  };
  const earnApyFunc = async () => {
    try {
      setMainLoader(true);
      let res = await hygtapyAmount()
      setHygtApyArr(res);
      setMainLoader(false);
    } catch (error) {
      // console.log(error, 'Error')
      setMainLoader(false);
    }
  };
  useEffect(() => {
    earnApyFunc()
  }, [])
  return (
    <>


      {mainLoader && <Loader />}
      <div className="content">
        <section className="main-earn">
          <div className="main-heading">
            <h4 className="ml-15">Stake HYDT</h4>
          </div>
          <div className="row">
            <div className="col-xl-4 col-12 padd-sm">
              <div className="left-content">
                <div className="upper-head">
                  <h6>Stake</h6>
                </div>
                <ButtonGroup className="time-select" aria-label="Basic example">
                  <Button
                    className={update == 0 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => {
                      setupdate(0);
                      setHydtAmount("");
                      setTotalReturns("");
                    }}
                    variant="secondary"
                  >
                    3 Months
                  </Button>
                  <Button
                    className={update == 1 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => {
                      setupdate(1);
                      setHydtAmount("");
                      setTotalReturns("");
                    }}
                    variant="secondary"
                  >
                    6 Months
                  </Button>
                  <Button
                    className={update == 2 ? "active-btn" : "yysyvsyvyxs"}
                    onClick={() => {
                      setupdate(2);
                      setHydtAmount("");
                      setTotalReturns("");
                    }}
                    variant="secondary"
                  >
                    12 Months
                  </Button>
                </ButtonGroup>
                <div className="option-field">
                  <div className="upper-area">
                    <label>Stake</label>
                    <h6>
                      {balanceOf ? parseFloat(balanceOf).toFixed(5) : 0}
                      <span>:Balance</span>
                    </h6>
                  </div>
                  <div className="input-field">
                    <input
                      type="number"
                      placeholder="Enter HYDT amount"
                      onChange={handleHydt}
                      value={hydtAmount}
                    />
                    <button onClick={handleMaxBal}>MAX</button>
                  </div>
                </div>
                <div className="slide-ranger">
                  {/* <input type="range" min="1" max="100" class="slider" id="myRange" onChange={(e)=>moveSlider(e)}/> */}
                  {/* <Slider
                    defaultValue={5}
                    onChangeCommitted={(e) => moveSlider(e)}
                    aria-label="Default"
                    valueLabelDisplay="auto"
                  /> */}
                  <Box sx={{ width: 250 }}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item xs>
                        <Slider
                          value={typeof value === "number" ? value : 0}
                          onChange={handleSliderChange}
                          aria-labelledby="input-slider"
                          valueLabelDisplay="auto"
                        />
                      </Grid>
                      <Grid item>
                        <Input
                          value={value}
                          size="small"
                          onChange={handleInputChange}
                          onBlur={handleBlur}
                        // inputProps={{
                        //   step: 10,
                        //   min: 0,
                        //   max: 100,
                        //   type: "number",
                        //   "aria-labelledby": "input-slider",
                        // }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/* <div className="inner-value">
                    <span>0%</span>
                    <span>100%</span>
                  </div> */}
                </div>
                <div className="bottom-text">
                  <div className="text">
                    <h6>Total Returns</h6>
                    <h5>
                      {totalReturns ? parseFloat(totalReturns).toFixed(5) : 0}{" "}
                      <span>
                        HYDT{" "}
                        <img
                          src="\assests\buttonsvgs\hydt.svg"
                          alt="img"
                          className="img-fluid ml-2x"
                        />
                      </span>
                    </h5>
                  </div>
                  <div className="text">
                    <h6>APY</h6>
                    {/* <h5>{apy}%</h5> jawaaaaad */}
                    <h5><img src="\assests\hydt-small-icon.svg" alt="img" className="img-fluid me-1" />{apy}% + <img src="\assests\hygt-small-icon.svg" alt="img" className="img-fluid me-1" />{parseFloat(hygtApyArr[update])?.toFixed(1)}%</h5>
                  </div>
                  <div className="text">
                    <h6>Daily Payout</h6>
                    <h5>
                      {payoutPer}% <span>daily</span>
                    </h5>
                  </div>
                  <div className="text">
                    <h6>Period</h6>
                    <h5>
                      {update === 0 && 90} {update === 1 && 180} {update === 2 && 365} <span>Days</span>
                    </h5>
                  </div>
                  <div className="text">
                    <h6>Fee</h6>
                    <h5>
                      {depositFee}% <span>of deposit amount</span>
                    </h5>
                  </div>
                </div>

                {allowance > 0 ? (
                  <>
                    <div
                      className="btn-approve"
                      onClick={() => StakeFun(update, hydtAmount)}
                    >
                      <button>Stake</button>
                    </div>
                    <div className="bottom-progress">
                      <span className="activespan unactivespan">1</span>
                      <img
                        src="\assests\buttonsvgs\line.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <span className="activespan ">2</span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="btn-approve" onClick={ApproveFun}>
                      <button>Approve</button>
                    </div>
                    <div className="bottom-progress">
                      <span className="activespan">1</span>
                      <img
                        src="\assests\buttonsvgs\line.svg"
                        alt="img"
                        className="img-fluid"
                      />
                      <span className="activespan unactivespan">2</span>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-xl-8 col-12 padd-sm">
              <div className="right-content">
                <div className="upper-head">
                  <h6>Active Staking</h6>
                  {/* <button className="btn-transparent">Claim All</button> */}
                </div>
                <div className="parent-items">
                  {/* {activeDeposit.length > 0 ?
                    activeDeposit?.map((item, index) => { */}
                  {
                    activeDeposit.length > 0 ?
                      activeDeposit?.map((item, index) => {
                        // console.log("item?.", item?.amount);

                        let date = new Date(item?.startTime * 1000);
                        const dateTime = moment(date).format("H:mm DD-MM-YYYY");
                        let date1 = new Date(item?.endTime * 1000);
                        const endTime = moment(date1).format("H:mm DD-MM-YYYY");
                        const currentTime = new Date().getTime();
                        const distanceToDate = item?.endTime * 1000 - currentTime;
                        // // console.log(
                        //   "distanceToDatedistanceToDate",
                        //   distanceToDate
                        // );

                        let x = (currentTime / 1000) - item?.startTime
                        let y = item?.endTime - item?.startTime
                        let progress = (x / y) * 100
                        // // console.log(
                        //   "lllllllllllllllqqqqqqqqq",
                        //   progress, item?.index
                        // );
                        return (
                          <>
                            <EarnChild
                              getDepositData={getDepositData}
                              item={item}
                              dateTime={dateTime}
                              endTime={endTime}
                              distanceToDate={distanceToDate}
                              progress={progress}
                              getBalance={getBalance}
                              HydtAllow111={HydtAllow111}
                              hygtApyArr={hygtApyArr}
                            />
                          </>
                        );
                      })
                      :
                      <p className="deposts mt-2">You currently have no active deposits</p>
                  }
                </div>
              </div>
              {/* <button className="load-btn">Load More</button> */}
              {/* <div className="pagination-custom">
                <div className="left">
                  <img
                    src="\assests\buttonsvgs\arrow-left.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
                <div className="inner-values">
                  <div className="spec">1</div> <span>/</span>{" "}
                  <div className="total">10</div>
                </div>
                <div className="right">
                  <img
                    src="\assests\buttonsvgs\arrow-right.svg"
                    alt="img"
                    className="img-fluid"
                  />
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Earn;