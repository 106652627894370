import { useCallback } from "react";
import { useWeb3React } from "@web3-react/core";
import useWeb3 from "../useWeb3"
import { earnAbi, getPancakeHygtHydtContract, initialMintV2Contract, farmAbi, getLpTokenContract } from "../../utils/contractHelpers";
import Environment from "../../utils/environment";
import axios from "axios";

export const FarmHygtApy = () => {
    const { account } = useWeb3React();
    const web3 = useWeb3();
    const contractAddress = Environment.EarnAbi;
    const controlAddress = Environment.intialMintV2;
    const pancakeHygtAddress = Environment.intialMintV2;
    const farmAddress = Environment.FarmAbi
    const earncontract = earnAbi(contractAddress, web3);
    const pancakeHygtcontract = getPancakeHygtHydtContract(pancakeHygtAddress, web3);
    const controlcontract = initialMintV2Contract(controlAddress, web3);
    const farmcontract = farmAbi(farmAddress, web3);

    const getTomiPrice = async (tokenAddress) => {
        try {

            if (tokenAddress === '0x55d398326f99059fF775485246999027B3197955') {
                let tokenPrice = 1
                let weiAmount = web3.utils.toWei(tokenPrice?.toString(), 'ether')
                return weiAmount;
            } else {
                const tokenData = await axios.get(`https://api.dexscreener.com/latest/dex/tokens/${tokenAddress}`);
                let tokenPrice = tokenData?.data?.pairs[0]?.priceUsd
                let weiAmount = web3.utils.toWei(tokenPrice?.toString(), 'ether')
                return weiAmount;
            }
        } catch (error) {
        }
    };


    const hygtapyAmount = useCallback(
        async () => {
            // let val = web3.utils.toWei(spendBnb.toString(), "ether");
            try {
                const hydtPrice = await controlcontract.methods.getCurrentPrice().call();
                // console.log('priiiiiiiiiiiiiii', hydtPrice)
                const hygtPairReserves = await pancakeHygtcontract.methods.getReserves().call();
                const hygtPrice = (hydtPrice * hygtPairReserves._reserve1) / hygtPairReserves._reserve0;
                const hygtPerBlock = await farmcontract.methods.HYGTPerBlock().call();
                const blocksPerYear = (86400 * 365) / 3;
                const hygtPerYearUSD = hygtPerBlock * blocksPerYear * hygtPrice;
                // console.log('apyArray', hygtPerBlock, blocksPerYear, hygtPrice)
                const totalAllocPoint = await farmcontract.methods.totalAllocPoint().call();
                const poolLength = await farmcontract.methods.poolLength().call();
                let apyArray = []
                for (let i = 0; i < poolLength; i++) {
                    const poolInfo = await farmcontract.methods.poolInfo(i).call();
                    const allocPoint = poolInfo.allocPoint;
                    if (allocPoint > 0) {
                        const lpToken = poolInfo.lpToken;
                        // console.log('lpToken:dddddddd ', poolInfo, lpToken)
                        const lpcontract = getLpTokenContract(lpToken, web3);
                        const token0 = await lpcontract.methods.token0().call();
                        const token1 = await lpcontract.methods.token1().call();
                        // console.log('tttoookkkeeennnsss', token0, '------', token1)
                        const price0 = await getTomiPrice(token0)// get price from coingecko or dexscreener if exists for token0, if does not exist, then compare address of token0 with address of hygt and hydt and use
                        // // the pre calculated price of either hygt or hydt
                        const price1 = await getTomiPrice(token1)// get price from coingecko or dexscreener if exists for token1. if does not exist, then compare address of token1 with address of hygt and hydt and use
                        // // the pre calculated price of either hygt or hydt
                        // console.log('priceeeeeeeee', price0, price1)
                        const reserves = await lpcontract.methods.getReserves().call();
                        const totalValueLockedUSD = price0 * reserves._reserve0 + price1 * reserves._reserve1;
                        const totalDeposit = await lpcontract.methods.balanceOf(farmAddress).call(); // convert from wei to eth
                        const totalSupply = await lpcontract.methods.totalSupply().call(); // convert from wei to eth
                        const ratio = totalDeposit / totalSupply || 1;
                        const tvlUsd = totalValueLockedUSD * ratio;
                        // console.log('apyArray', allocPoint, totalAllocPoint, hygtPerYearUSD, tvlUsd)
                        const APY = (
                            ((allocPoint / totalAllocPoint) * hygtPerYearUSD) /
                            tvlUsd
                        ) * 100;
                        apyArray.push(APY)
                    }

                }
                // console.log('apyArray', apyArray)
                return apyArray
            } catch (error) {
                // console.log('9999999', error)
                throw error;
            }
        }, [earncontract]
    );
    return { hygtapyAmount: hygtapyAmount };
}
export default FarmHygtApy;

