import "./dashboard.scss";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import ClaimRewardsD from "../../hooks/dataFetchers/claimRewardsD";
import ClaimRewardsD1 from "../../hooks/dataFetchers/claimRewardsD1";
import ClaimRewardsD2 from "../../hooks/dataFetchers/claimRewardsD2";
import DepositRewardsD2 from "../../hooks/dataFetchers/depositRewardsD2";
import Loader from "../../hooks/loader";
import useWeb3 from "../../hooks/useWeb3";
import { useWeb3React } from "@web3-react/core";
import { useState, useEffect } from 'react';
import Environment from "../../utils/environment";
import axios from "axios";
import DepositRewardsD1 from "../../hooks/dataFetchers/depositRewardsD1";

function Dashboard() {
  const history = useHistory();
  const { dashRewards } = ClaimRewardsD();
  const { dashRewardsOne } = ClaimRewardsD1();
  const { dashRewardsTwo } = ClaimRewardsD2();
  const [pendingRew, setPendingRew] = useState("");
  const [pendingRew1, setPendingRew1] = useState("");
  const [pendingRew2, setPendingRew2] = useState("");
  const [amountActive, setAmount] = useState("");

  const { dashdepositd1 } = DepositRewardsD1();
  const { dashdepositd2 } = DepositRewardsD2();
  const [depositRew, DepositRewardsD] = useState("");
  const [depositRew1, setDepositRewardsD1] = useState("");
  const [depositRew2, setDepositRewardsD2] = useState("");
  const [mainLoader, setMainLoader] = useState(false);

  const web3 = useWeb3();
  const { account } = useWeb3React();
  const handleRew = async () => {
    try {
      setMainLoader(true);
      let result = await dashRewards();
      console.log('00000ppppp', result);
      setPendingRew(result);
      setMainLoader(false);
    }
    catch {
      setMainLoader(false);
    }
  };
  const handleRewone = async () => {
    try {
      setMainLoader(true);
      let result = await dashRewardsOne();
      setPendingRew1(result);
      setMainLoader(false);

    }
    catch {
      setMainLoader(false);
    }
  };
  const handleRewtwo = async () => {
    try {
      setMainLoader(true);
      let result = await dashRewardsTwo();
      setPendingRew2(result);
      setMainLoader(false);
    }
    catch {
      setMainLoader(false);
    }
  };

  const handleDepRewtwo = async () => {
    try {
      setMainLoader(true);
      let result = await dashdepositd2();
      setDepositRewardsD2(result);
      setMainLoader(false);
    }
    catch {
      setMainLoader(false);
    }
  };
  const handleDepRewOne = async () => {
    try {
      setMainLoader(true);
      let result = await dashdepositd1();
      setDepositRewardsD1(result);
      setMainLoader(false);
    }
    catch {
      setMainLoader(false);
    }
  };


  useEffect(() => {
    handleRew();
    handleRewone();
    handleRewtwo();
    handleDepRewtwo();
    handleDepRewOne();
  }, [account]);
  //Graph Apis

  const getActiveDeposit = () => {
    setMainLoader(true);
    let account1 = account.toLowerCase();
    let acc = JSON.stringify(account1 + "-Earn");
    // let accounttt = acc.split("-")[1]; 
    var data = JSON.stringify({
      query: `query 
      MyQuery {userVolumes(where: {id: ${acc}}) {
        amount
    }
      }`,
    });
    var config = {
      method: "post",
      url: Environment.hydtGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };
    axios(config)
      .then(function (response) {
        console.log("minted111111", response?.data?.data?.userVolumes[0].amount);
        setAmount(web3.utils.fromWei(response?.data?.data?.userVolumes[0].amount, "ether"));
        setMainLoader(false);
      })
      .catch(function (error) {
        setMainLoader(false);
      });
  };
  useEffect(() => {
    if (account) {
      getActiveDeposit();
    }
  }, [account]);
  console.log("depositRew2depositRew2", amountActive);
  return (
    <>
      {mainLoader && <Loader />}
      <div className="content">
        <section className="main-dashboard">
          <div className="first">
            <div className="main-heading">
              <h4 className="ml-15">Reward Overview</h4>
            </div>
            <div className="content-main">
              <div className="row">
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card">
                    <div className="upper-head">
                      <h6>Pending HYDT Rewards</h6>
                    </div>
                    <div className="bottom-content">
                      <div className="left">
                        <p><img src="\assests\sidebar-icons\earn.svg" alt="img" className="img-fluid mr-2" />HYDT Staking</p>
                        <h6>{pendingRew ? parseFloat(pendingRew).toFixed(6) : 0} <span>HYDT <img src="\assests\buttonsvgs\hydt.svg" alt="img" className="img-fluid ml-2x" /></span></h6>
                      </div>
                      <div className="right">
                        <Link to={"./earn"}>
                          <button className="btn-transparent">Claim</button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8 col-12 padd-sm">
                  <div className="inner-card mb-0">
                    <div className="upper-head">
                      <h6>Pending HYGT Rewards</h6>
                    </div>
                    <div className="twice-inline">
                      <div className="bottom-content">
                        <div className="left">
                          <p><img src="\assests\sidebar-icons\earn.svg" alt="img" className="img-fluid mr-2" />HYGT Staking</p>
                          <h6>{pendingRew1 ? parseFloat(pendingRew1).toFixed(6) : 0}<span>HYGT <img src="\assests\buttonsvgs\hygt.svg" alt="img" className="img-fluid ml-2x" /></span></h6>
                        </div>
                        <div className="right">
                          <Link to={"./earn"}>
                            <button className="btn-transparent">Claim</button>
                          </Link>
                        </div>
                      </div>
                      <span className="line"></span>
                      <div className="bottom-content">
                        <div className="left">
                          <p><img src="\assests\sidebar-icons\farm.svg" alt="img" className="img-fluid mr-2" />Farm</p>
                          <h6>{pendingRew2 ? parseFloat(pendingRew2).toFixed(6) : 0}<span>HYGT <img src="\assests\buttonsvgs\hygt.svg" alt="img" className="img-fluid ml-2x" /></span></h6>
                        </div>
                        <div className="right">
                          <Link to={"./farm"}>
                            <button className="btn-transparent">Claim</button>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="second">
            <div className="main-heading">
              <h4 className="ml-15">Deposit Overview</h4>
            </div>
            <div className="content-main">
              <div className="row">
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card">
                    <div className="upper-head">
                      <h6>My Active Staking</h6>
                      <Link to={"./earn"}>
                        <button className="btn-transparent">Deposit</button>
                      </Link>
                    </div>
                    <div className="bottom-content">
                      <div className="left">
                        <h6>{amountActive ? parseFloat(amountActive).toFixed(10) : 0} <span>HYDT <img src="\assests\buttonsvgs\hydt.svg" alt="img" className="img-fluid ml-2x" /></span></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card">
                    <div className="upper-head">
                      <h6>Daily HYDT Rewards</h6>
                      {/* <button className="btn-transparent">Claim</button> */}
                    </div>
                    <div className="bottom-content">
                      <div className="left">
                        <h6>{depositRew1 ? parseFloat(depositRew1).toFixed(10) : 0} <span>HYDT <img src="\assests\buttonsvgs\hydt.svg" alt="img" className="img-fluid ml-2x" /></span></h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-12 padd-sm">
                  <div className="inner-card">
                    <div className="upper-head">
                      <h6>HYDT/USD Rate</h6>
                      {/* <button className="btn-transparent">Claim</button> */}
                    </div>
                    <div className="bottom-content">
                      <div className="left">
                        <h6>{depositRew2 ? parseFloat(depositRew2).toFixed(7) : 0} <span>HYDT/USD</span></h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    </>
  );
}
export default Dashboard;